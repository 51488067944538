import React, {useEffect} from "react"
import Layout from "../components/layout"
// import SEO from "../components/seo"
import informationImage from "../../static/images/Information-document.svg";
import eventImage from "../../static/images/Event-managment.svg";
import ProductivityImage from "../../static/images/Increase-Productivity.svg";
import happinessImage from "../../static/images/Make-happiness.svg";
import axios from 'axios';
import { navigate } from "@reach/router"
import { useQueryParam, StringParam } from "use-query-params";

const MyClock = () => {
  const [data, setData] = React.useState(null)
  const [uuid] = useQueryParam("uuid", StringParam);
  // const uuid = location.pathname.split('/').slice(-1).pop()

  const fetchData = async () => {
    if (!uuid) return
    try{
      const result = await axios({
        method: 'post',
        url:  `/.netlify/functions/getDevice`,
        data: {
          key: uuid,
        }
      });
      console.log('### doc', result.data);
      setData(result.data);
    } catch (err) {
      // if device not exist (or someone is typing randomly) send to 404
      console.log(err);
      navigate('/404/');
    }
  };

  useEffect(() => {
    fetchData();
  }, [])

  return(
    <Layout>

      {!data && 
        <div className={"container"}>
          <div className={"targets"}>
            <div className={"title"}>
              <h2>Configura il mio orologio</h2>
              <p>Caricamento...</p>
            </div>
          </div>
        </div>
      }
      
      {data && data.wifiIp && 
        <div className={"container"}>
          <div className={"targets"}>
            <div className={"title"}>
              <h2>Configura il mio orologio</h2>
              <p>Personalizza le opzioni del tuo orologio dalla app integrata.</p>
            </div>
            <div className={"row"}>
              <div className={"col-12"}>
                <div className={"item"}>
                <div className={"link-btn"}>
                  <a href={`http://${data.wifiIp}/`}>Apri il pannello di controllo</a>
                </div>
                  <p>Accedi al pannello di controllo del tuo parologio.</p>
                  <p>Potrai salvare la pagina come app desktop per accere più rapidamente la prossima volta!</p>
                </div>                
                <div>
                  <a href="#" onClick={() => setData({...data, wifiIp: false })}>
                    Problemi di accesso?
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      }

      {data && !data.wifiIp &&
        <div className={"container"}>
          <div className={"targets"}>
            <div className={"title"}>
              <h2>Configurazione Iniziale</h2>
            </div>

            <div className={"row"}>
              <div className={"col-6"}>
                <div className={"item"}>
                  <img alt={"Information"} src={informationImage}/>
                  <h3>Accedi al tuo parologio dal tuo smartphone</h3>
                  <p>Usa il QRcode o i dati indicati per collegarti alla rete privata del tuo parologio.
                  (con questo metodo potrai accedere alle impostazioni tutte le volte che vorrai, ma collegandolo alla wifi potrai accedere più rapidamente)</p>
                </div>
              </div>

              <div className={"col-6"}>
                <div className={"item"}>
                  <img alt={"Event"} src={eventImage}/>
                  <h3>Connetti il tuo parologio alla wifi di casa</h3>
                  <p>Nelle impostazioni seleziona le impostazioni WiFi, scansiona la rete, seleziona la tua wifi domestica ed inserisci la password.</p>
                </div>
              </div>
            </div>

            <div className={"row"}>
              <div className={"col-6"}>
                <div className={"item"}>
                  <img alt={"Productivity"} src={ProductivityImage}/>
                  <h3>Torna alla wifi di casa</h3>
                  <p>Scollega il tuo smartphone dal tuo parologio e riconnettilo alla wifi di casa.</p>
                </div>
              </div>

              <div className={"col-6"}>
                <div className={"item"}>
                  <img alt={"Happiness"} src={happinessImage}/>
                  <h3>Accedi alla pagina di configurazione</h3>
                  <p>Usa il Qrcode per la configurazione, potrai vedere se è connesso ad internet e potrai accere alla sua configurazione.</p>
                </div>
              </div>
            </div>
          </div>
        </div>  
      }
      
    </Layout>
)}

export default MyClock
